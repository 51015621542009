import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Favourite things of the week`}</h1>
    <h4>{`Quote of the week`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "796px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/69da70742bae776a3e2c711ac95fa944/d48f1/Quote-2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABpElEQVQoz4WS/2/SQBiH+f8Tf9FEE7NE48zQZDG6ZYMom8SNxH3RTJCJw5KN0lIGo5RAS3vtPV6vFSNh8W0/93nv3rsn17sWJJL0vVfJSp6sGdOIjFNIm1g1C1UJVOYTaZ8r9xFqXOp+Wg/zealmqpfOCTVKLqEFxceajXje2GezWeblj/dsXVUotg94drGr/UWjxNbPiq4Xrw54bXxkb3TOxvk7is0PzEWQYpAyB44WU8rdU8o3J1SHdSr9rxy5lxwOLtj7VeNt+xP7vVN2OrXcj6l6TUr2GaXrzwRx+C/QE3O+zDo0Y5t6aPJdWDQik4bocUlffVbCaiT6ALOQGU27BnY9hwfVTTbquzw+2ebhcZEnZ294VHvF0287+rxSZpIoTCK1skv5k8slVAN9GdFjgolLN7lTGmuZUrmSWNmhlJLlk+fZTnNgFIZ02gbj4R0D28E2LUaDITeday3bsmi1WhiGQRD8vYB1oYFxLLgd9HH6FsNbR+WOdtcdM51O8X0fz/NwJxM1N/4/MC3GIkKEC0SkrkAtEpH6A5Nk7aL7YGn8Bmkc9p8mSa4aAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "quote",
            "title": "quote",
            "src": "/static/69da70742bae776a3e2c711ac95fa944/d48f1/Quote-2.png",
            "srcSet": ["/static/69da70742bae776a3e2c711ac95fa944/772e8/Quote-2.png 200w", "/static/69da70742bae776a3e2c711ac95fa944/e17e5/Quote-2.png 400w", "/static/69da70742bae776a3e2c711ac95fa944/d48f1/Quote-2.png 796w"],
            "sizes": "(max-width: 796px) 100vw, 796px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h5><strong parentName="h5"><em parentName="strong">{`Resurfaced with `}<a parentName="em" {...{
            "href": "https://readwise.io/"
          }}>{`readwise.io`}</a></em></strong></h5>
    <h4>{`Tweet of the week`}</h4>
    <blockquote className="twitter-tweet" data-dnt="true">
  <p lang="en" dir="ltr">
    Check for updates
    <a href="https://t.co/XTt1l5P3aV">pic.twitter.com/XTt1l5P3aV</a>
  </p>
  &mdash; Conor White-Sullivan (@Conaw)
  <a href="https://twitter.com/Conaw/status/1309184269361639429?ref_src=twsrc%5Etfw">
    September 24, 2020
  </a>
    </blockquote>
    <h4>{`A JS library`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9ae26da7dce94760f39751dfc67a77d7/e92cd/gatsby-site.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABJ0AAASdAHeZh94AAACVklEQVQ4y42TyU8TURzH+Rc4e+FE4tWLB2I8ePLsyXjy5MGDRuOFGA2ujaHuiiRoICwhpokEy6LRCBixpUQoS23LMkCZhqVOaYcuM+3043tTutCo8Zv5Zt7M+73v+611COwnMsS1PWwUxFMo2JTI5fNkDYNsNmvTyBoYRoUJLUV8Vycv7CTq7ENmHtM0i3o1gqW1pGVZB0KmLSwv8n1exjMcLtvXVR+UB0osfVfvy9typlH2phbSpiz4L1TbmJZIQSaNpm3x4tNHHCMfRFpy5X075IJVYHExgLKmEAqH8M/5iWxGCIaCqOpmUWh9nf2O1+gzfpKZFKGgh77xEa50tuJbiRS9z+WKgjK075Nepsd/8m1knonhGZbmI/jGAiwtbNiCajTObNtVvO2XeNX3jht3nDz3NDMdeozLE2Y7ZQjHrAMPhXoyqaPH0yR+pdD3MqT0rHinRfJlwQyW1ufp7Oqlf+grkc433Go8TX1DPSeaGuiZ9HEhEKwKWQjG9+LsxraJbqkoisKasoayqrCzExP5ivHAcZbRt21cG9ihf1wh6p2i6fgpjjQe5dj5m5x0qYdzuLWhsRHeZldNiCpaIh95+7+8TFY1qaeZmpug/6mbHsHulw+Z8Hm4HlNxecNcHNYqglJgqOMHXS1jDLZPizBzNi0pSLHCy6ZGy2w3o4PdNN91MnT5DNFH51j2PxN9mbFt+Z+2Ke1rhs6X1Ulan7Ry776DlYEONntvY4ScwihT6cPaaahlbR9KuN3v8S8E7HWew5P1V0H48whWT0lBNLk9q1RsfgM/qLyx0sADEQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Gatsby",
            "title": "Gatsby",
            "src": "/static/9ae26da7dce94760f39751dfc67a77d7/5a190/gatsby-site.png",
            "srcSet": ["/static/9ae26da7dce94760f39751dfc67a77d7/772e8/gatsby-site.png 200w", "/static/9ae26da7dce94760f39751dfc67a77d7/e17e5/gatsby-site.png 400w", "/static/9ae26da7dce94760f39751dfc67a77d7/5a190/gatsby-site.png 800w", "/static/9ae26da7dce94760f39751dfc67a77d7/c1b63/gatsby-site.png 1200w", "/static/9ae26da7dce94760f39751dfc67a77d7/29007/gatsby-site.png 1600w", "/static/9ae26da7dce94760f39751dfc67a77d7/e92cd/gatsby-site.png 1778w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Gatsby is amazing!!`}</p>
    <p>{`In order to explain why Gatsby is so amazing let me explain the differences
between my experience without Gatsby and with Gatsby`}</p>
    <p>{`Just see this! This is what I have to do when I have to publish something`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d390395262f604216a67bcdc485486a1/d61c2/old-site-process.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABr0lEQVQ4y31T7ZKcMAy7d7kuBySEfJIAu9O+/0upcgJ7y3WnPzQG4yiWbD6U2xAikf/Ahg16XtBrTwTcRot+ihgIib2OzDnceovbYPGrn/H5ZWrsmJe6j2FKGFg4mgWKzzEWxH2DzyuUT5h8qVAm8ZCv+FKB4DNJPztdoxAKKqHcLOhYmMIDKe2Y0oopZMzseo4rtMuVVM2tASFt577PC56EUtRRZvI7tu03/P7Ast6R8g5PckPyKSzQPkNbwpUqsRGH5wWXDk/iEFbEQrLtUaXbUIhcSUd2efrZpF9BwuVCeBaaOSNSesh3zF6kL5jdAkdSZQ9V4r8MzTTIHN4TypBYbG0hcam+KUu5c/NQvBy5DaM5fLUN2q3/Eopkw0LD23olqyOTpRyie4G8i5LukCo56fhCKJ1JV7KXUyocwAJD8zW7O6WNc2owh9wpXqb+JBw4frkt+DucXzG6Q6b5JpCo6GOFPXImvSc8TRbv3k3vv9Dh5x42/RO969WLRy9eVbzJd+PPtTFtWo7/dFx2uO2OsN/hGf0qa8Mcd9HnwmVf+X1tkbDMhVLgFsLnatFfqm2TY3q7MA4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "old-process",
            "title": "old-process",
            "src": "/static/d390395262f604216a67bcdc485486a1/5a190/old-site-process.png",
            "srcSet": ["/static/d390395262f604216a67bcdc485486a1/772e8/old-site-process.png 200w", "/static/d390395262f604216a67bcdc485486a1/e17e5/old-site-process.png 400w", "/static/d390395262f604216a67bcdc485486a1/5a190/old-site-process.png 800w", "/static/d390395262f604216a67bcdc485486a1/c1b63/old-site-process.png 1200w", "/static/d390395262f604216a67bcdc485486a1/29007/old-site-process.png 1600w", "/static/d390395262f604216a67bcdc485486a1/d61c2/old-site-process.png 1800w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This is me converting my markdown document to HTML. Yup! That is pretty
tedious`}</p>
    <p>{`Then I have to take out 30 words from this using
`}<a parentName="p" {...{
        "href": "https://wordcounter.net/"
      }}>{`Wordcounter.net`}</a></p>
    <p>{`Then we I have to paste those 30 words in a card template thing.
Then I have to take that filled in template and paste it in 2 places.
That was without Gatsby. Now how does Gatsby help me?
I just paste my markdown in to a markdown file like this`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e9027c07547464429c630d99fa2d6227/f18a9/new-site-process.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABrklEQVQ4y62SW5KjMAxFWcs8AgFjg8EPTOhMd0/N/nekuVcmqVR/98cpWbIsXctuzHqInbOMUxa33MTHN5nWmziNJWUAvQ3Sjat0ZpHf10n51Tn52Vq1nfFyxX4zjBGFkhgfpZ+Ai9gISmdWufReLsNS7YPTZ9EfFyOXq5MW63bw0hgU9AvUQYVzUGSjjIhZWsCYPePWJo3bM9aPbP4g4BYJCl2W7fgn+fZXUvmUJf2RNQPYABu3dwmg+u/wPySWygqf+HjXUc3hgEJ0SkzEgYQCGRT6TEx3bUBLJs7bF2WcNxmf6+ob0PSQGpGsRVXZXZWQJVe1VOCWXQY0f8z3Kz1GQRo6PLztuPL+KQFErBWMIGEUC/ZnXMnh9Q1+A5X0z+IRrx+kxQNyrQoDFGTMJKPAhoJbqeTtQ+NsVtiAM0Mub+ExLx/e1M5Q73h1NNNX3pF4QMnBYphZAYwVXJd+hrqMwysUzn4HBf90w8/IaqeprvnyDf/aAIcDJuYc7jOGgfM7XDEfXqvFH6TlufYLjDUdE14+bksG/4xVv+Ywl4e6V/8VLXhW/i6aR/fv4j8osZuzNkQbswAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "new-process",
            "title": "new-process",
            "src": "/static/e9027c07547464429c630d99fa2d6227/5a190/new-site-process.png",
            "srcSet": ["/static/e9027c07547464429c630d99fa2d6227/772e8/new-site-process.png 200w", "/static/e9027c07547464429c630d99fa2d6227/e17e5/new-site-process.png 400w", "/static/e9027c07547464429c630d99fa2d6227/5a190/new-site-process.png 800w", "/static/e9027c07547464429c630d99fa2d6227/c1b63/new-site-process.png 1200w", "/static/e9027c07547464429c630d99fa2d6227/29007/new-site-process.png 1600w", "/static/e9027c07547464429c630d99fa2d6227/f18a9/new-site-process.png 1799w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
After pasting it I fill in the stuff at the top and it all auto populates I don't
need to do anything more!!`}</p>
    <p>{`At this point you should see how and why is Gatsby just such a helpful tool`}</p>
    <h2>{`PostScript`}</h2>
    <p>{`I have still not totally moved on to Gatsby but will probably do so in this
week`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      